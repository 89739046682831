export default function IKBottomDividerWave({ color }: { color?: string }) {
  return (
    <div className='absolute bottom-0 left-0 h-max w-full translate-y-[calc(100%_-_1px)] transform overflow-hidden'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className={`w-[200%] md:w-full [&_path]:fill-${color || 'charcoal'}`}
        width='1464'
        height='26'
        viewBox='0 0 1464 26'
        fill='none'
        preserveAspectRatio='none'
      >
        <path
          d='M0 0V13.4014C71.6641 8.27045 311.629 -4.51068 731.531 15.2506C1153.28 35.099 1393.51 22.1175 1464 17.0324V0H0Z'
          fill='none'
        />
      </svg>
    </div>
  );
}
