'use client';
import { m } from 'framer-motion';
import { useAtom } from 'jotai';
import { currentIndustryAtom } from '@/store/industryStore';
import IndustryCard from '@/components/sections/industry-personalization/IndustryCard';
import { PortableText } from '@portabletext/react';
import { Industry } from '@/types/types';
import { IKButton } from '@/components/shared/material';
import { MenuItemLink } from '@/types/types';
import { ArrowRight } from '@phosphor-icons/react';
import { useEffect, useState, useRef } from 'react';

interface IndustryBentoBoxProps {
  industries: Industry[];
  featuredImages: { image: { url: string } }[];
  setCurrentIndustry: (industry: Industry | null) => void;
  ctaHeading?: any;
  ctaCopy?: any;
  mainButton?: {
    text: string;
    link: MenuItemLink;
  };
  components: any;
}

export default function IndustryBentoBox({
  industries,
  featuredImages,
  ctaHeading,
  ctaCopy,
  mainButton,
  components,
}: IndustryBentoBoxProps) {
  const [, setCurrentIndustry] = useAtom(currentIndustryAtom);
  const [highlightGlow, setHighlightGlow] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const sectionRef = useRef<HTMLDivElement>(null);

  const handleUserInteraction = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setHighlightGlow(false);
    timeoutRef.current = setTimeout(() => {
      setHighlightGlow(true);
    }, 3000); // 5 seconds timeout for glow effect
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        handleUserInteraction();
      } else {
        setHighlightGlow(false);
      }
    }, options);

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleInteraction = () => handleUserInteraction();

    if (sectionRef.current) {
      sectionRef.current.addEventListener('mousemove', handleInteraction);
      sectionRef.current.addEventListener('click', handleInteraction);
      sectionRef.current.addEventListener('keydown', handleInteraction);
    }

    return () => {
      if (sectionRef.current) {
        sectionRef.current.removeEventListener('mousemove', handleInteraction);
        sectionRef.current.removeEventListener('click', handleInteraction);
        sectionRef.current.removeEventListener('keydown', handleInteraction);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className='my-4 flex flex-col gap-4'>
      <div className='flex items-center justify-between gap-4'>
        <m.button
          onClick={() => setCurrentIndustry(industries[0])}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ scale: 1.02 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 12,
            duration: 0.1,
            ease: 'easeInOut',
          }}
          className={`relative w-1/3 overflow-hidden rounded-[24px]`}
        >
          <IndustryCard
            title={industries[0].title}
            description={industries[0].description}
            industryColor={industries[0].industryColor}
            industryIcon={industries[0].industryIcon}
          />
        </m.button>
        <m.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ scale: 1.02 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 12,
            duration: 0.1,
            ease: 'easeInOut',
          }}
          className={`relative w-1/3 overflow-hidden rounded-[24px]`}
        >
          <img
            src={featuredImages[0].image.url}
            alt=''
            className='h-64 max-h-64 w-full rounded-[24px] object-cover'
          />
        </m.div>
        <m.button
          onClick={() => setCurrentIndustry(industries[1])}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ scale: 1.02 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 12,
            duration: 0.1,
            ease: 'easeInOut',
          }}
          className={`relative w-1/3 overflow-hidden rounded-[24px]`}
        >
          <IndustryCard
            title={industries[1].title}
            description={industries[1].description}
            industryColor={industries[1].industryColor}
            industryIcon={industries[1].industryIcon}
          />
        </m.button>
      </div>
      <div className='flex items-center justify-between gap-4'>
        <m.div
          initial={{ opacity: 0, y: 0, x: -50 }}
          animate={{ opacity: 1, y: 0, x: 0 }}
          whileHover={{ scale: 1.02 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 12,
            duration: 0.1,
            ease: 'easeInOut',
          }}
          className={`relative w-[30%] overflow-hidden rounded-[24px]`}
        >
          <img
            src={featuredImages[1].image.url}
            alt=''
            className='h-64 max-h-64 w-full rounded-[24px] object-cover'
          />
        </m.div>

        <m.button
          onClick={() => setCurrentIndustry(industries[2])}
          initial={{ opacity: 0, y: -20, x: 0 }}
          animate={{ opacity: 1, y: 0, x: 0 }}
          whileHover={{ scale: 1.02 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 12,
            duration: 0.1,
            ease: 'easeInOut',
          }}
          className={`relative w-[45%] overflow-hidden rounded-[24px]`}
        >
          <IndustryCard
            title={industries[2].title}
            description={industries[2].description}
            industryColor={industries[2].industryColor}
            industryIcon={industries[2].industryIcon}
          />
        </m.button>
        <m.button
          onClick={() => setCurrentIndustry(industries[3])}
          initial={{ opacity: 0, y: 0, x: 50 }}
          animate={{ opacity: 1, y: 0, x: 0 }}
          whileHover={{ scale: 1.02 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 12,
            duration: 0.1,
            ease: 'easeInOut',
          }}
          className={`relative w-[45%] overflow-hidden rounded-[24px]`}
        >
          <IndustryCard
            title={industries[3].title}
            description={industries[3].description}
            industryColor={industries[3].industryColor}
            industryIcon={industries[3].industryIcon}
          />
        </m.button>
      </div>
      <div className='flex items-center justify-between gap-4'>
        <m.button
          onClick={() => setCurrentIndustry(industries[4])}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ scale: 1.02 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 12,
            duration: 0.1,
            ease: 'easeInOut',
          }}
          className={`relative w-[40%] overflow-hidden rounded-[24px]`}
        >
          <IndustryCard
            title={industries[4].title}
            description={industries[4].description}
            industryColor={industries[4].industryColor}
            industryIcon={industries[4].industryIcon}
          />
        </m.button>

        {/* Find your toolkit */}
        <m.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ scale: 1.02 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 12,
            duration: 0.1,
            ease: 'easeInOut',
          }}
          ref={sectionRef}
          className={`relative w-[60%] overflow-hidden rounded-[24px] ${highlightGlow ? 'animate-pulse-glow' : ''}`}
        >
          <div className="flex h-64 flex-col items-center justify-center gap-4 rounded-[24px] border border-[#51504D] bg-[url('/images/cta-frame.png')] bg-cover bg-center px-20 py-10">
            <h3 className='heading-md text-center font-normal text-[#FFEAB1] [&_strong]:font-display [&_strong]:tracking-tighter [&_strong]:text-yellow'>
              {ctaHeading && (
                <PortableText components={components} value={ctaHeading} />
              )}
            </h3>
            <p className='text-center text-lg text-white [&_strong]:text-[#FFEAB1]'>
              {ctaCopy && (
                <PortableText components={components} value={ctaCopy} />
              )}
            </p>

            <IKButton
              link={mainButton?.link}
              className={`group flex w-full cursor-pointer items-center justify-center gap-3 border border-gray-600 !bg-[#282825] !text-[#EFE9DB] !shadow-none hover:!bg-charcoal-800 sm:w-auto`}
            >
              <span>{mainButton?.text}</span>
              <span
                className={`flex h-6 w-6 items-center justify-center rounded-full bg-yellow text-charcoal duration-150 ease-out group-hover:ml-2 [&_path]:!stroke-charcoal [&_path]:!stroke-[8px]`}
              >
                <ArrowRight weight='bold' />
              </span>
            </IKButton>
          </div>
        </m.div>
      </div>
    </div>
  );
}
