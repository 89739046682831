import { useAtom } from 'jotai';
import { currentIndustryAtom } from '@/store/industryStore';

type IndustryTabProps = {
  title: string;
  industryIcon?: any;
  industryColor?: {
    label: string;
    value: string;
  };
};

export default function IndustryTab({
  title,
  industryColor,
  industryIcon,
}: IndustryTabProps) {
  const [currentIndustry] = useAtom(currentIndustryAtom);
  const tabColor = industryColor?.label || 'Pink';

  return (
    <>
      {currentIndustry?.title === title ? (
        <div
          className={`inline-flex items-center justify-center gap-2 rounded px-4 py-2 ${
            tabColor === 'White' ? 'bg-[#EFE9DB] text-black' : ''
          } ${tabColor === 'Orange' ? 'bg-[#D64C19] text-white' : ''} ${
            tabColor === 'Teal' ? 'bg-[#009EAC] text-white' : ''
          } ${tabColor === 'Pink' ? 'bg-[#D72A4E] text-white' : ''} ${
            tabColor === 'Yellow' ? 'bg-[#FFCD00] text-black' : ''
          }`}
        >
          <div
            className={`flex !h-[35px] min-w-[35px] max-w-[35px] shrink items-center justify-center rounded-full text-white ${
              tabColor === 'White' ? 'bg-[#51504D]' : ''
            } ${tabColor === 'Orange' ? 'bg-[#91451C]' : ''} ${
              tabColor === 'Teal' ? 'bg-[#025057]' : ''
            } ${tabColor === 'Pink' ? 'bg-[#5F1222]' : ''} ${
              tabColor === 'Yellow' ? 'bg-[#775705]' : ''
            }`}
          >
            {industryIcon?.url && (
              <img
                src={industryIcon?.url}
                alt=''
                className='h-[16px] w-[16px] object-contain'
              />
            )}
          </div>
          <p className='whitespace-nowrap text-sm'>{title}</p>
        </div>
      ) : (
        <div className='inline-flex items-center justify-center gap-2 rounded bg-[#51504D] px-4 py-2 text-white'>
          <div
            className={`flex !h-[35px] min-w-[35px] max-w-[35px] shrink items-center justify-center rounded-full text-white ${
              tabColor === 'White' ? 'bg-[#EFE9DB]' : ''
            } ${tabColor === 'Orange' ? 'bg-[#F09E80]' : ''} ${
              tabColor === 'Teal' ? 'bg-[#8BD0D5]' : ''
            } ${tabColor === 'Pink' ? 'bg-[#E77E94]' : ''} ${
              tabColor === 'Yellow' ? 'bg-[#FFEAB1]' : ''
            }`}
          >
            {industryIcon?.url && (
              <img
                src={industryIcon?.url}
                alt=''
                className='h-[16px] w-[16px] object-contain invert'
              />
            )}
          </div>
          <p className='whitespace-nowrap text-sm'>{title}</p>
        </div>
      )}
    </>
  );
}
