'use client';
import { FaArrowLeft } from 'react-icons/fa6';
import IndustryTab from './IndustryTab';
import { useAtom } from 'jotai';
import { currentIndustryAtom } from '@/store/industryStore';
import { Industry } from '@/types/types';
import styles from './IndustryStickyNav.module.css';

interface IndustryNavProps {
  industries: Industry[];
}

export default function IndustryNav({ industries }: IndustryNavProps) {
  const [, setCurrentIndustry] = useAtom(currentIndustryAtom);

  return (
    <div className={`container`}>
      <div className={`block w-full bg-charcoal`}>
        <div
          className={`flex items-center justify-start gap-4 overflow-x-scroll py-4 ${styles.scrollHide}`}
        >
          <button
            onClick={() => setCurrentIndustry(null)}
            className='inline-flex items-center justify-center gap-2 rounded bg-[#51504D] px-4 py-2'
          >
            <FaArrowLeft
              size={14}
              className='!h-[35px] min-w-[35px] max-w-[35px] rounded-full bg-white object-contain p-2 text-[#51504D]'
            />
            <p className='whitespace-nowrap text-sm text-white'>
              All industries
            </p>
          </button>

          {industries?.map((industry, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndustry(industries[index])}
            >
              <IndustryTab
                title={industry.title}
                industryIcon={industry?.industryIcon}
                industryColor={industry?.industryColor}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
