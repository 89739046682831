'use client';
import { useMemo } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { m } from 'framer-motion';
import { IKPattern } from '@/components/shared/patterns';

type IndustryCardProps = {
  title: string;
  description: string;
  industryColor?: {
    label: string;
    value: string;
  };
  industryIcon?: any;
};

export default function IndustryCard(props: IndustryCardProps) {
  const cardColor = props.industryColor?.label || 'pink';
  const industryColor = cardColor.toLowerCase();

  const SVGPatternColor = useMemo(() => {
    let colorClass = '';
    switch (industryColor) {
      case 'teal':
        colorClass = '[&_path]:fill-teal-700 fill-teal-700';
        break;
      case 'pink':
        colorClass = 'fill-pink-800 [&_path]:fill-pink-800';
        break;
      case 'charcoal':
        colorClass = 'fill-charcoal-800 [&_path]:fill-charcoal-800';
        break;
      case 'orange':
        colorClass = 'fill-orange-500 [&_path]:fill-orange-500';
        break;
      case 'yellow':
        colorClass = 'fill-yellow-200 [&_path]:fill-yellow-200';
        break;
      default:
        break;
    }
    return colorClass;
  }, [cardColor]);

  return (
    <m.div
      className={`group relative h-72 overflow-hidden rounded-[24px] border lg:h-64 ${
        industryColor === 'white' ? 'border-[#EFE9DB] bg-white' : ''
      } ${industryColor === 'orange' ? 'border-[#D64C19] bg-orange' : ''} ${
        industryColor === 'teal' ? 'border-[#009EAC] bg-teal' : ''
      } ${industryColor === 'pink' ? 'border-[#D72A4E] bg-pink' : ''} ${
        industryColor === 'yellow' ? 'border-[#FFCD00] bg-yellow' : ''
      }`}
    >
      <div
        className={`relative z-20 h-full rounded-[24px] bg-gradient-to-b p-6 duration-200 lg:group-hover:h-[80%] ${
          industryColor === 'white' ? 'from-[#EFE9DB] to-[#676560]' : ''
        } ${industryColor === 'orange' ? 'from-[#EE6912] to-[#571700]' : ''} ${
          industryColor === 'teal' ? 'from-[#0BA6B1] to-[#005E66]' : ''
        } ${industryColor === 'pink' ? 'from-[#B92342] to-[#56101F]' : ''} ${
          industryColor === 'yellow' ? 'from-[#FFCD00] to-[#F7AA04]' : ''
        } `}
      >
        <div className='absolute left-0 top-0 z-0 h-full w-full overflow-hidden rounded-3xl'>
          <IKPattern
            color={industryColor as 'orange' | 'teal' | 'pink' | 'charcoal'}
            patternId={1}
            pattternProps={{
              className: `${SVGPatternColor}`,
            }}
            className={`absolute left-0 top-0 z-0 h-[1700px] w-[2000px] rounded-3xl opacity-[5%] ${SVGPatternColor}`}
          />
        </div>
        {/* box content start */}
        <div>
          <div className='flex flex-col items-start justify-start gap-2 md:flex-row md:items-center'>
            <div
              className={`relative flex max-h-[40px] min-h-[40px] min-w-[40px] max-w-[40px] items-center justify-center ${
                industryColor === 'white' ? 'bg-[#51504D]' : ''
              } ${industryColor === 'orange' ? 'bg-[#91451C]' : ''} ${
                industryColor === 'teal' ? 'bg-[#025057]' : ''
              } ${industryColor === 'pink' ? 'bg-[#5F1222]' : ''} ${
                industryColor === 'yellow' ? 'bg-[#AA7F0F]' : ''
              } rounded-full`}
            >
              {/* Pulsing background div */}
              <div
                className={`absolute inset-0 rounded-full group-hover:animate-ping ${
                  industryColor === 'white' ? 'bg-[#51504d83]' : ''
                } ${industryColor === 'orange' ? 'bg-[#91451C83]' : ''} ${
                  industryColor === 'teal' ? 'bg-[#02505783]' : ''
                } ${industryColor === 'pink' ? 'bg-[#5F122283]' : ''} ${
                  industryColor === 'yellow' ? 'bg-[#AA7F0F83]' : ''
                } `}
              />

              <div className='relative z-10 flex items-center justify-center rounded-full'>
                {props.industryIcon?.url && (
                  <img
                    src={props.industryIcon?.url}
                    alt=''
                    className='h-[24px] w-[24px] object-contain'
                  />
                )}
              </div>
            </div>

            <h3
              className={`z-10 text-left text-xl font-medium will-change-transform md:text-2xl ${industryColor === 'white' || industryColor === 'yellow' ? 'text-[#282825]' : 'text-white'}`}
            >
              {props.title}
            </h3>
          </div>
          <div className='mt-2 md:mt-4'>
            <h4
              className={`text-left duration-200 will-change-transform md:text-lg ${industryColor === 'white' || industryColor === 'yellow' ? 'text-[#282825]' : 'text-white'} ${
                industryColor === 'white' ? 'group-hover:text-gray-700' : ''
              } ${industryColor === 'orange' ? 'group-hover:text-orange-200' : ''} ${
                industryColor === 'teal' ? 'group-hover:text-teal-100' : ''
              } ${industryColor === 'pink' ? 'group-hover:text-pink-200' : ''} ${
                industryColor === 'yellow' ? 'group-hover:text-yellow-900' : ''
              }`}
            >
              {props.description}
            </h4>
          </div>
        </div>
        {/* box content end */}
      </div>
      <div className='absolute bottom-3 left-[50%] z-50 flex items-center justify-center'>
        <div
          className={`flex w-full items-center justify-center bg-transparent opacity-55 duration-100 ${
            industryColor === 'white'
              ? 'text-white group-hover:text-[#282825]'
              : ''
          } ${industryColor === 'orange' ? 'text-white' : ''} ${
            industryColor === 'teal' ? 'text-white' : ''
          } ${industryColor === 'pink' ? 'text-white' : ''} ${
            industryColor === 'yellow'
              ? 'text-white group-hover:text-[#282825]'
              : ''
          } group-hover:opacity-100`}
        >
          <FaArrowRight
            size={20}
            className='duration-150 group-hover:translate-x-1'
          />
        </div>
      </div>
    </m.div>
  );
}
