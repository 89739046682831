'use client';
import { useRef, useMemo } from 'react';
import { PiStorefrontFill } from 'react-icons/pi';
import { PiMoneyWavyFill } from 'react-icons/pi';
import { FaArrowRight } from 'react-icons/fa6';
import { components } from '@/components/sections/PortableTextComponents';
import { PortableText } from '@portabletext/react';
import Image from 'next/image';
import { m, useInView } from 'framer-motion';
import { Industry } from '@/types/types';
import { BsArrowRightCircleFill } from 'react-icons/bs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper/modules';
import { IKPattern } from '@/components/shared/patterns';
import { IKLink } from '@/components/shared/material';

export default function IndustryShowcase(props: Industry) {
  const cardColor = props.industryColor?.label || 'pink';
  const industryColor = cardColor.toLowerCase();

  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  const SVGPatternColor = useMemo(() => {
    let colorClass = '';
    switch (industryColor) {
      case 'teal':
        colorClass = '[&_path]:fill-teal-700 fill-teal-700';
        break;
      case 'pink':
        colorClass = 'fill-pink-800 [&_path]:fill-pink-800';
        break;
      case 'charcoal':
        colorClass = 'fill-charcoal-800 [&_path]:fill-charcoal-800';
        break;
      case 'orange':
        colorClass = 'fill-orange-500 [&_path]:fill-orange-500';
        break;
      case 'yellow':
        colorClass = 'fill-yellow-200 [&_path]:fill-yellow-200';
        break;
      default:
        break;
    }
    return colorClass;
  }, [cardColor]);

  const paginationButtonClass = `disabled:opacity-0 cursor-pointer [&_path]:fill-black fill-black h-12 w-12 shadow-lg absolute rounded-full bg-white hover:bg-white flex items-center justify-center hover:scale-105 p-2.5`;

  const leftButton = `${paginationButtonClass} -left-2 swiper-button-outline-left`;
  const rightButton = `${paginationButtonClass} -right-2 swiper-button-outline-right`;

  const spotlightSection = useRef(null);
  const spotlightInView = useInView(spotlightSection, {
    amount: 0.5,
    once: true,
  });

  return (
    <div className='relative flex flex-col gap-4 text-white'>
      <div className='absolute left-0 top-0 z-0 h-full w-full overflow-hidden rounded-[32px]'>
        <IKPattern
          color={industryColor as 'orange' | 'teal' | 'pink' | 'charcoal'}
          patternId={1}
          pattternProps={{
            className: `${SVGPatternColor}`,
          }}
          className={`absolute left-0 top-0 z-0 h-[3500px] w-[4000px] rounded-[32px] opacity-[5%] ${SVGPatternColor}`}
        />
      </div>
      {/* Main box */}
      <div className='mb-12 grid grid-cols-1 items-center lg:mb-0 lg:grid-cols-3'>
        {/* <div className='col-span-2 flex flex-col items-start justify-between gap-6 rounded-3xl bg-red-600 bg-gradient-to-b from-[#b92341df] to-[#56101ff8] p-8 lg:pr-36'> */}
        <m.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            type: 'tween',
            ease: 'easeInOut',
            duration: 0.6,
            delay: 0.1,
          }}
          exit={{ opacity: 0, x: -50 }}
          className={`col-span-2 rounded-[34px] border ${
            industryColor === 'white' ? 'border-white' : ''
          } ${industryColor === 'orange' ? 'border-orange-600' : ''} ${
            industryColor === 'teal' ? 'border-teal-600' : ''
          } ${industryColor === 'pink' ? 'border-pink-600' : ''} ${
            industryColor === 'yellow' ? 'border-yellow-500' : ''
          }`}
        >
          {/* GRADIENTS */}
          <div
            className={`flex flex-col items-start justify-between gap-6 rounded-[32px] bg-gradient-to-b p-6 lg:p-8 lg:pr-36 ${
              industryColor === 'white' ? 'from-[#EFE9DB] to-[#DFD9CD]' : ''
            } ${industryColor === 'orange' ? 'from-[#EE6912] to-[#E76511]' : ''} ${
              industryColor === 'teal' ? 'from-[#0BA5B0] to-[#0F8893]' : ''
            } ${industryColor === 'pink' ? 'from-[#B82342] to-[#B72341]' : ''} ${
              industryColor === 'yellow' ? 'from-[#FFCD00] to-[#FEC900]' : ''
            } `}
          >
            <div className='z-10 flex items-center justify-start gap-4'>
              <div
                className={`flex !h-[40px] min-w-[40px] max-w-[40px] shrink items-center justify-center rounded-full text-white ${
                  industryColor === 'white' ? 'bg-[#51504D]' : ''
                } ${industryColor === 'orange' ? 'bg-[#91451C]' : ''} ${
                  industryColor === 'teal' ? 'bg-[#025057]' : ''
                } ${industryColor === 'pink' ? 'bg-[#5F1222]' : ''} ${
                  industryColor === 'yellow' ? 'bg-[#775705]' : ''
                }`}
              >
                {props.industryIcon?.url && (
                  <img
                    src={props.industryIcon?.url}
                    alt=''
                    className='h-[20px] w-[20px] object-contain'
                  />
                )}
              </div>
              <div>
                <h3
                  className={`heading-lg font-display font-semibold tracking-tighter ${industryColor === 'white' || industryColor === 'yellow' ? 'text-[#282825]' : 'text-white drop-shadow-lg'}`}
                >
                  {props.title}
                </h3>
                <p
                  className={`${industryColor === 'white' || industryColor === 'yellow' ? 'text-[#282825]' : 'text-white'}`}
                >
                  {props.description}
                </p>
              </div>
            </div>
            <div className='z-10 w-full lg:hidden'>
              <Image
                className='h-[250px] max-h-[250px] w-full rounded-[16px] object-cover shadow-xl'
                src={props.featuredImage.url}
                height={250}
                width={500}
                alt={props.featuredImage.alt || ''}
              />
            </div>
            <div className='flex w-full flex-col items-start justify-start gap-4'>
              <div
                className={`z-10 flex w-full items-center justify-start gap-4 rounded-3xl ${
                  industryColor === 'white' ? 'bg-[#EFE9DB] text-[#51504D]' : ''
                } ${industryColor === 'orange' ? 'bg-[#F09E80] text-[#91451C]' : ''} ${
                  industryColor === 'teal' ? 'bg-[#8BD0D5] text-[#025057]' : ''
                } ${industryColor === 'pink' ? 'bg-[#E77E94] text-[#5F1222]' : ''} ${
                  industryColor === 'yellow'
                    ? 'bg-[#FFEAB1] text-[#775705]'
                    : ''
                } p-4 shadow-lg`}
              >
                <PiStorefrontFill
                  size={20}
                  className={`flex !h-[40px] min-w-[40px] max-w-[40px] shrink items-center justify-center rounded-full object-contain p-2 text-white ${
                    industryColor === 'white' ? 'bg-[#51504D]' : ''
                  } ${industryColor === 'orange' ? 'bg-[#91451C]' : ''} ${
                    industryColor === 'teal' ? 'bg-[#025057]' : ''
                  } ${industryColor === 'pink' ? 'bg-[#5F1222]' : ''} ${
                    industryColor === 'yellow' ? 'bg-[#775705]' : ''
                  }`}
                />
                <div>
                  <h4 className='heading-xs font-display font-semibold tracking-tighter'>
                    {props.totalMerchants}
                  </h4>
                  <p className='text-sm'>
                    Number of merchants in your industry who trust iKhokha to
                    power their business.
                  </p>
                </div>
              </div>
              <div
                className={`z-10 -mb-16 flex w-full items-center justify-start gap-4 rounded-3xl lg:-mb-0 ${
                  industryColor === 'white' ? 'bg-[#EFE9DB] text-[#51504D]' : ''
                } ${industryColor === 'orange' ? 'bg-[#F09E80] text-[#91451C]' : ''} ${
                  industryColor === 'teal' ? 'bg-[#8BD0D5] text-[#025057]' : ''
                } ${industryColor === 'pink' ? 'bg-[#E77E94] text-[#5F1222]' : ''} ${
                  industryColor === 'yellow'
                    ? 'bg-[#FFEAB1] text-[#775705]'
                    : ''
                } p-4 shadow-lg`}
              >
                <PiMoneyWavyFill
                  size={20}
                  className={`flex !h-[40px] min-w-[40px] max-w-[40px] shrink items-center justify-center rounded-full object-contain p-2 text-white ${
                    industryColor === 'white' ? 'bg-[#51504D]' : ''
                  } ${industryColor === 'orange' ? 'bg-[#91451C]' : ''} ${
                    industryColor === 'teal' ? 'bg-[#025057]' : ''
                  } ${industryColor === 'pink' ? 'bg-[#5F1222]' : ''} ${
                    industryColor === 'yellow' ? 'bg-[#775705]' : ''
                  }`}
                />
                <div>
                  <h4 className='heading-xs font-display font-semibold tracking-tighter'>
                    {props.totalTransactions}
                  </h4>
                  <p className='text-sm'>
                    Total transactions merchants in your industry made between
                    2024 & 2025.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </m.div>
        <div className='z-10 my-8 hidden lg:block'>
          <m.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            transition={{
              type: 'tween',
              ease: 'easeInOut',
              duration: 0.6,
              delay: 0.1,
            }}
            className='col-span-1 -ml-20 overflow-hidden rounded-[32px] shadow-xl'
          >
            <Image
              className='h-[280px] max-h-[280px] w-full object-cover'
              src={props.featuredImage.url}
              height={250}
              width={500}
              alt={props.featuredImage.alt || ''}
            />
          </m.div>
        </div>
      </div>

      {/* Merchant spotlight */}
      <m.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{
          type: 'tween',
          ease: 'easeInOut',
          duration: 0.6,
          delay: 0.1,
        }}
        ref={spotlightSection}
        className={`overflow-hidden rounded-[32px] border ${
          industryColor === 'white' ? 'border-white' : ''
        } ${industryColor === 'orange' ? 'border-orange-600' : ''} ${
          industryColor === 'teal' ? 'border-teal-600' : ''
        } ${industryColor === 'pink' ? 'border-pink-600' : ''} ${
          industryColor === 'yellow' ? 'border-yellow-500' : ''
        }`}
      >
        {/* GRADIENTS */}
        <div
          className={`grid grid-cols-1 items-start gap-8 bg-gradient-to-b p-6 lg:grid-cols-3 lg:gap-12 lg:p-8 ${
            industryColor === 'white' ? 'from-[#DFD9CD] to-[#6C6A64]' : ''
          } ${industryColor === 'orange' ? 'from-[#E76511] to-[#571700]' : ''} ${
            industryColor === 'teal' ? 'from-[#0F8893] to-[#015E67]' : ''
          } ${industryColor === 'pink' ? 'from-[#B72341] to-[#6B1829]' : ''} ${
            industryColor === 'yellow' ? 'from-[#FEC900] to-[#F7AB04]' : ''
          } `}
        >
          <div className='flex flex-col justify-end gap-4 lg:col-span-2'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center justify-start gap-4'>
                <Image
                  className='h-16 w-16'
                  src={props.profilePicture.url}
                  height={200}
                  width={200}
                  alt={''}
                />
                <div>
                  <h3
                    className={`heading-md font-display font-semibold tracking-tighter ${industryColor === 'white' || industryColor === 'yellow' ? 'text-[#282825]' : 'text-white drop-shadow-lg'}`}
                  >
                    {props.merchantName}
                  </h3>
                  <p
                    className={`${industryColor === 'white' || industryColor === 'yellow' ? 'text-[#282825]' : 'text-white'}`}
                  >
                    {props.merchantDescription}
                  </p>
                </div>
              </div>

              {props.mainButton?.link && (
                <IKLink
                  link={props.mainButton?.link}
                  className={`group z-10 hidden rounded-xl bg-white bg-opacity-80 px-4 py-2 font-medium hover:bg-opacity-95 ${
                    industryColor === 'white'
                      ? 'text-[#51504D] hover:text-[#51504D]'
                      : ''
                  } ${industryColor === 'orange' ? 'text-[#91451C] hover:text-[#91451C]' : ''} ${
                    industryColor === 'teal'
                      ? 'text-[#025057] hover:text-[#025057]'
                      : ''
                  } ${industryColor === 'pink' ? 'text-[#5F1222] hover:text-[#5F1222]' : ''} ${
                    industryColor === 'yellow'
                      ? 'text-[#775705] hover:text-[#775705]'
                      : ''
                  } items-center justify-center gap-4 shadow-lg duration-300 group-hover:scale-105 lg:flex`}
                >
                  <p>Find your solution</p>
                  <BsArrowRightCircleFill
                    size={20}
                    className='duration-300 group-hover:translate-x-1'
                  />
                </IKLink>
              )}
            </div>
            <div className='grid grid-cols-1 items-end justify-end gap-4 pt-10 md:grid-cols-2 lg:grid-cols-12'>
              {/* Main product */}
              {props.products[0].productLink?.slug && (
                <IKLink
                  link={{
                    url: `${props.products[0].productLink?.slug}`,
                    type: 'internal',
                    document: 'page',
                  }}
                  className='z-10 col-span-1 lg:col-span-5'
                >
                  <m.div
                    initial={{ opacity: 0, y: 40 }}
                    animate={
                      spotlightInView
                        ? { opacity: 1, y: 0 }
                        : { opacity: 0, y: 40 }
                    }
                    whileHover={{
                      scale: 1.02,
                      transition: {
                        duration: 0.2,
                        ease: 'easeInOut',
                        delay: 0,
                      }, // no delay here
                    }}
                    transition={{
                      opacity: { duration: 0.6, delay: 0.1 },
                      y: { duration: 0.6, delay: 0.1 },
                    }}
                    className={`group mt-10 hidden rounded-2xl shadow-lg lg:block ${
                      industryColor === 'white' ? 'bg-[#C2BDB3]' : ''
                    } ${industryColor === 'orange' ? 'bg-[#D75D0F]' : ''} ${
                      industryColor === 'teal' ? 'bg-[#09828B]' : ''
                    } ${industryColor === 'pink' ? 'bg-[#C32F4E]' : ''} ${
                      industryColor === 'yellow' ? 'bg-[#FFCD00]' : ''
                    }`}
                  >
                    <div className='grid grid-cols-2 items-center justify-between gap-4'>
                      <Image
                        src={props.products[0].image.url}
                        height={1000}
                        width={1000}
                        alt={props.products[0].image.alt || ''}
                        className='cols-span-1 -mt-20 mb-6 max-h-[200px] object-contain duration-300 group-hover:scale-105 lg:mb-0'
                      />
                      <div className='flex flex-col items-end gap-4 pt-8'>
                        <p className='rounded-l-lg bg-white px-4 py-2 text-xs text-black opacity-50 md:text-sm'>
                          {props.products[0].feature1}
                        </p>
                        <p
                          className={`rounded-l-lg bg-gradient-to-b px-4 py-2 text-xs text-white md:text-sm ${
                            industryColor === 'white'
                              ? 'from-[#8A8A81] to-[#3D3D3A]'
                              : ''
                          } ${industryColor === 'orange' ? 'from-[#DF662C] to-[#A03919]' : ''} ${
                            industryColor === 'teal'
                              ? 'from-[#08727A] to-[#045E66]'
                              : ''
                          } ${industryColor === 'pink' ? 'from-[#b92341df] to-[#56101ff8]' : ''} ${
                            industryColor === 'yellow'
                              ? 'from-[#D0AF58] to-[#B2891F]'
                              : ''
                          } `}
                        >
                          {props.products[0].feature2}
                        </p>
                      </div>
                    </div>
                    <div className='flex items-center justify-between gap-8 p-6 pt-0 lg:p-8'>
                      <div
                        className={`text-base ${industryColor === 'white' || industryColor === 'yellow' ? 'text-[#282825]' : 'text-white'} ${
                          industryColor === 'white'
                            ? '[&_strong]:text-[#282825]'
                            : ''
                        } ${industryColor === 'orange' ? '[&_strong]:text-[#8A390D]' : ''} ${
                          industryColor === 'teal'
                            ? '[&_strong]:text-[#8BD0D5]'
                            : ''
                        } ${industryColor === 'pink' ? '[&_strong]:text-[#640E20]' : ''} ${
                          industryColor === 'yellow'
                            ? '[&_strong]:text-[#282825]'
                            : ''
                        }`}
                      >
                        {props.products[0].description && (
                          <PortableText
                            components={components}
                            value={props.products[0].description}
                          ></PortableText>
                        )}
                      </div>
                      <FaArrowRight
                        size={45}
                        color={`${
                          industryColor === 'white' ? '#51504D' : ''
                        } ${industryColor === 'orange' ? '#91451C' : ''} ${
                          industryColor === 'teal' ? '#025057' : ''
                        } ${industryColor === 'pink' ? '#5F1222' : ''} ${
                          industryColor === 'yellow' ? '#775705' : ''
                        }`}
                        className='min-w-[25px] duration-300 group-hover:translate-x-1'
                      />
                    </div>
                  </m.div>
                  <m.div
                    whileHover={{
                      scale: 1.02,
                      transition: {
                        duration: 0.2,
                        ease: 'easeInOut',
                        delay: 0,
                      },
                    }}
                    className={`group mt-10 block rounded-2xl shadow-lg lg:hidden ${
                      industryColor === 'white' ? 'bg-[#C2BDB3]' : ''
                    } ${industryColor === 'orange' ? 'bg-[#D75D0F]' : ''} ${
                      industryColor === 'teal' ? 'bg-[#09828B]' : ''
                    } ${industryColor === 'pink' ? 'bg-[#C32F4E]' : ''} ${
                      industryColor === 'yellow' ? 'bg-[#FFCD00]' : ''
                    }`}
                  >
                    <div className='grid grid-cols-2 items-center justify-between gap-4'>
                      <Image
                        src={props.products[0].image.url}
                        height={1000}
                        width={1000}
                        alt={props.products[0].image.alt || ''}
                        className='cols-span-1 -mt-20 mb-6 max-h-[200px] object-contain duration-300 group-hover:scale-105 lg:mb-0'
                      />
                      <div className='flex flex-col items-end gap-4 pt-8'>
                        <p className='rounded-l-lg bg-white px-4 py-2 text-sm text-black opacity-50'>
                          {props.products[0].feature1}
                        </p>
                        <p
                          className={`rounded-l-lg bg-gradient-to-b px-4 py-2 text-sm text-white ${
                            industryColor === 'white'
                              ? 'from-[#8A8A81] to-[#3D3D3A]'
                              : ''
                          } ${industryColor === 'orange' ? 'from-[#DF662C] to-[#A03919]' : ''} ${
                            industryColor === 'teal'
                              ? 'from-[#08727A] to-[#045E66]'
                              : ''
                          } ${industryColor === 'pink' ? 'from-[#b92341df] to-[#56101ff8]' : ''} ${
                            industryColor === 'yellow'
                              ? 'from-[#D0AF58] to-[#B2891F]'
                              : ''
                          } `}
                        >
                          {props.products[0].feature2}
                        </p>
                      </div>
                    </div>
                    <div className='flex items-center justify-between gap-8 p-6 pt-0 lg:p-8'>
                      <div
                        className={`text-base ${industryColor === 'white' || industryColor === 'yellow' ? 'text-[#282825]' : 'text-white'} ${
                          industryColor === 'white'
                            ? '[&_strong]:text-[#282825]'
                            : ''
                        } ${industryColor === 'orange' ? '[&_strong]:text-[#8A390D]' : ''} ${
                          industryColor === 'teal'
                            ? '[&_strong]:text-[#8BD0D5]'
                            : ''
                        } ${industryColor === 'pink' ? '[&_strong]:text-[#640E20]' : ''} ${
                          industryColor === 'yellow'
                            ? '[&_strong]:text-[#282825]'
                            : ''
                        }`}
                      >
                        {props.products[0].description && (
                          <PortableText
                            components={components}
                            value={props.products[0].description}
                          ></PortableText>
                        )}
                      </div>
                      <FaArrowRight
                        size={45}
                        color={`${
                          industryColor === 'white' ? '#51504D' : ''
                        } ${industryColor === 'orange' ? '#91451C' : ''} ${
                          industryColor === 'teal' ? '#025057' : ''
                        } ${industryColor === 'pink' ? '#5F1222' : ''} ${
                          industryColor === 'yellow' ? '#775705' : ''
                        }`}
                        className='min-w-[25px] duration-300 group-hover:translate-x-1'
                      />
                    </div>
                  </m.div>
                </IKLink>
              )}

              <div className='col-span-1 hidden w-full items-end justify-between gap-4 md:flex md:flex-col lg:col-span-7'>
                {/* product 2 */}
                {props.products[1].productLink?.slug && (
                  <IKLink
                    link={{
                      url: `${props.products[1].productLink?.slug}`,
                      type: 'internal',
                      document: 'page',
                    }}
                    className='z-10 w-full'
                  >
                    <m.div
                      initial={{ opacity: 0, y: 40 }}
                      animate={
                        spotlightInView
                          ? { opacity: 1, y: 0 }
                          : { opacity: 0, y: 40 }
                      }
                      whileHover={{
                        scale: 1.02,
                        transition: {
                          duration: 0.2,
                          ease: 'easeInOut',
                          delay: 0,
                        }, // no delay here
                      }}
                      transition={{
                        opacity: { duration: 0.6, delay: 0.2 },
                        y: { duration: 0.6, delay: 0.2 },
                      }}
                      className={`group grid grid-cols-4 items-center gap-2 overflow-hidden rounded-2xl bg-opacity-55 ${
                        industryColor === 'white' ? 'bg-[#51504D99]' : ''
                      } ${industryColor === 'orange' ? 'bg-[#7A330D99]' : ''} ${
                        industryColor === 'teal' ? 'bg-[#02505799]' : ''
                      } ${industryColor === 'pink' ? 'bg-[#59061899]' : ''} ${
                        industryColor === 'yellow' ? 'bg-[#77570599]' : ''
                      }`}
                    >
                      <Image
                        src={props.products[1].image.url}
                        height={1000}
                        width={1000}
                        alt={props.products[1].image.alt || ''}
                        className='col-span-1 h-full object-cover'
                      />
                      <div className='col-span-3 flex items-center justify-between gap-4 p-8 pl-2'>
                        <div
                          className={`text-left ${
                            industryColor === 'white'
                              ? '[&_strong]:text-[#EFE9DB]'
                              : ''
                          } ${industryColor === 'orange' ? '[&_strong]:text-[#F09E80]' : ''} ${
                            industryColor === 'teal'
                              ? '[&_strong]:text-[#8BD0D5]'
                              : ''
                          } ${industryColor === 'pink' ? '[&_strong]:text-[#E77E94]' : ''} ${
                            industryColor === 'yellow'
                              ? '[&_strong]:text-[#FFCD00]'
                              : ''
                          }`}
                        >
                          {props.products[1].description && (
                            <PortableText
                              components={components}
                              value={props.products[1].description}
                            ></PortableText>
                          )}
                        </div>
                        <FaArrowRight
                          size={45}
                          color={`${
                            industryColor === 'white' ? '#EFE9DB' : ''
                          } ${industryColor === 'orange' ? '#F09E80' : ''} ${
                            industryColor === 'teal' ? '#009EAC' : ''
                          } ${industryColor === 'pink' ? '#E77E94' : ''} ${
                            industryColor === 'yellow' ? '#FFCD00' : ''
                          }`}
                          className='min-w-[25px] duration-500 group-hover:translate-x-1'
                        />
                      </div>
                    </m.div>
                  </IKLink>
                )}

                {/* Product 3 */}
                {props.products[2].productLink?.slug && (
                  <IKLink
                    link={{
                      url: `${props.products[2].productLink?.slug}`,
                      type: 'internal',
                      document: 'page',
                    }}
                    className='z-10 w-full'
                  >
                    <m.div
                      initial={{ opacity: 0, y: 40 }}
                      animate={
                        spotlightInView
                          ? { opacity: 1, y: 0 }
                          : { opacity: 0, y: 40 }
                      }
                      whileHover={{
                        scale: 1.02,
                        transition: {
                          duration: 0.2,
                          ease: 'easeInOut',
                          delay: 0,
                        }, // no delay here
                      }}
                      transition={{
                        opacity: { duration: 0.6, delay: 0.3 },
                        y: { duration: 0.6, delay: 0.3 },
                      }}
                      className={`group grid grid-cols-4 items-center gap-2 overflow-hidden rounded-2xl bg-opacity-55 ${
                        industryColor === 'white' ? 'bg-[#51504D99]' : ''
                      } ${industryColor === 'orange' ? 'bg-[#7A330D99]' : ''} ${
                        industryColor === 'teal' ? 'bg-[#02505799]' : ''
                      } ${industryColor === 'pink' ? 'bg-[#59061899]' : ''} ${
                        industryColor === 'yellow' ? 'bg-[#77570599]' : ''
                      }`}
                    >
                      <div className='flex h-full items-end'>
                        <Image
                          src={props.products[2].image.url}
                          height={1000}
                          width={1000}
                          alt={props.products[2].image.alt || ''}
                          className='col-span-1 h-full object-cover'
                        />
                      </div>
                      <div className='col-span-3 flex items-center justify-between gap-4 p-8 pl-2'>
                        <div
                          className={`text-left ${
                            industryColor === 'white'
                              ? '[&_strong]:text-[#EFE9DB]'
                              : ''
                          } ${industryColor === 'orange' ? '[&_strong]:text-[#F09E80]' : ''} ${
                            industryColor === 'teal'
                              ? '[&_strong]:text-[#8BD0D5]'
                              : ''
                          } ${industryColor === 'pink' ? '[&_strong]:text-[#E77E94]' : ''} ${
                            industryColor === 'yellow'
                              ? '[&_strong]:text-[#FFCD00]'
                              : ''
                          }`}
                        >
                          {props.products[2].description && (
                            <PortableText
                              components={components}
                              value={props.products[2].description}
                            ></PortableText>
                          )}
                        </div>
                        <FaArrowRight
                          size={45}
                          color={`${
                            industryColor === 'white' ? '#EFE9DB' : ''
                          } ${industryColor === 'orange' ? '#F09E80' : ''} ${
                            industryColor === 'teal' ? '#009EAC' : ''
                          } ${industryColor === 'pink' ? '#E77E94' : ''} ${
                            industryColor === 'yellow' ? '#FFCD00' : ''
                          }`}
                          className='min-w-[25px] duration-500 group-hover:translate-x-1'
                        />
                      </div>
                    </m.div>
                  </IKLink>
                )}
              </div>
              <div className='block md:hidden'>
                <Swiper
                  className='relative my-4 !flex flex-col !overflow-visible'
                  modules={[Navigation, Scrollbar, A11y]}
                  navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                    disabledClass: 'disabled',
                  }}
                  slidesPerView='auto'
                  spaceBetween={15}
                >
                  <SwiperSlide>
                    {props.products[1].productLink?.slug && (
                      <IKLink
                        link={{
                          url: `${props.products[1].productLink?.slug}`,
                          type: 'internal',
                          document: 'page',
                        }}
                      >
                        <m.div
                          whileHover={{
                            scale: 1.02,
                            transition: {
                              duration: 0.2,
                              ease: 'easeInOut',
                              delay: 0,
                            },
                          }}
                          className={`group z-10 overflow-hidden rounded-2xl bg-opacity-55`}
                        >
                          <Image
                            src={props.products[1].mobileImage.url || ''}
                            height={1000}
                            width={1000}
                            alt={props.products[1].mobileImage.alt || ''}
                            className='h-[198px] w-full rounded-t-2xl object-cover'
                          />
                          <div
                            className={`flex h-44 flex-col items-center justify-between gap-4 rounded-b-2xl px-6 py-4 ${
                              industryColor === 'white' ? 'bg-[#51504D99]' : ''
                            } ${industryColor === 'orange' ? 'bg-[#7A330D99]' : ''} ${
                              industryColor === 'teal' ? 'bg-[#02505799]' : ''
                            } ${industryColor === 'pink' ? 'bg-[#59061899]' : ''} ${
                              industryColor === 'yellow' ? 'bg-[#77570599]' : ''
                            }`}
                          >
                            <div
                              className={`text-left ${
                                industryColor === 'white'
                                  ? '[&_strong]:text-[#EFE9DB]'
                                  : ''
                              } ${industryColor === 'orange' ? '[&_strong]:text-[#F09E80]' : ''} ${
                                industryColor === 'teal'
                                  ? '[&_strong]:text-[#8BD0D5]'
                                  : ''
                              } ${industryColor === 'pink' ? '[&_strong]:text-[#E77E94]' : ''} ${
                                industryColor === 'yellow'
                                  ? '[&_strong]:text-[#FFCD00]'
                                  : ''
                              }`}
                            >
                              {props.products[1].description && (
                                <PortableText
                                  components={components}
                                  value={props.products[1].description}
                                ></PortableText>
                              )}
                            </div>
                            <FaArrowRight
                              size={35}
                              color={`${
                                industryColor === 'white' ? '#EFE9DB' : ''
                              } ${industryColor === 'orange' ? '#F09E80' : ''} ${
                                industryColor === 'teal' ? '#009EAC' : ''
                              } ${industryColor === 'pink' ? '#E77E94' : ''} ${
                                industryColor === 'yellow' ? '#FFCD00' : ''
                              }`}
                              className='min-w-[15px] duration-500 group-hover:translate-x-1'
                            />
                          </div>
                        </m.div>
                      </IKLink>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {props.products[2].productLink?.slug && (
                      <IKLink
                        link={{
                          url: `${props.products[2].productLink?.slug}`,
                          type: 'internal',
                          document: 'page',
                        }}
                      >
                        <m.div
                          whileHover={{
                            scale: 1.02,
                            transition: {
                              duration: 0.2,
                              ease: 'easeInOut',
                              delay: 0,
                            },
                          }}
                          className={`group z-10 overflow-hidden rounded-2xl bg-opacity-55`}
                        >
                          <Image
                            src={props.products[2].mobileImage.url || ''}
                            height={1000}
                            width={1000}
                            alt={props.products[2].mobileImage.alt || ''}
                            className='h-[198px] w-full rounded-t-2xl object-cover'
                          />
                          <div
                            className={`flex h-44 flex-col items-center justify-between gap-4 rounded-b-2xl px-6 py-4 ${
                              industryColor === 'white' ? 'bg-[#51504D99]' : ''
                            } ${industryColor === 'orange' ? 'bg-[#7A330D99]' : ''} ${
                              industryColor === 'teal' ? 'bg-[#02505799]' : ''
                            } ${industryColor === 'pink' ? 'bg-[#59061899]' : ''} ${
                              industryColor === 'yellow' ? 'bg-[#77570599]' : ''
                            }`}
                          >
                            <div
                              className={`text-left ${
                                industryColor === 'white'
                                  ? '[&_strong]:text-[#EFE9DB]'
                                  : ''
                              } ${industryColor === 'orange' ? '[&_strong]:text-[#F09E80]' : ''} ${
                                industryColor === 'teal'
                                  ? '[&_strong]:text-[#8BD0D5]'
                                  : ''
                              } ${industryColor === 'pink' ? '[&_strong]:text-[#E77E94]' : ''} ${
                                industryColor === 'yellow'
                                  ? '[&_strong]:text-[#FFCD00]'
                                  : ''
                              }`}
                            >
                              {props.products[2].description && (
                                <PortableText
                                  components={components}
                                  value={props.products[2].description}
                                ></PortableText>
                              )}
                            </div>
                            <FaArrowRight
                              size={35}
                              color={`${
                                industryColor === 'white' ? '#EFE9DB' : ''
                              } ${industryColor === 'orange' ? '#F09E80' : ''} ${
                                industryColor === 'teal' ? '#009EAC' : ''
                              } ${industryColor === 'pink' ? '#E77E94' : ''} ${
                                industryColor === 'yellow' ? '#FFCD00' : ''
                              }`}
                              className='min-w-[15px] duration-500 group-hover:translate-x-1'
                            />
                          </div>
                        </m.div>
                      </IKLink>
                    )}
                  </SwiperSlide>

                  <div className='absolute left-0 top-[50%] z-40 block w-full -translate-y-8 transform'>
                    <button ref={prevRef} className={leftButton}>
                      <svg
                        className='h-5 w-5 rotate-180 transform'
                        xmlns='http://www.w3.org/2000/svg'
                        width='17'
                        height='16'
                        viewBox='0 0 17 16'
                        fill='none'
                      >
                        <path
                          d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                          fill='none'
                        />
                      </svg>
                    </button>
                    <button ref={nextRef} className={rightButton}>
                      <svg
                        className='h-5 w-5'
                        xmlns='http://www.w3.org/2000/svg'
                        width='17'
                        height='16'
                        viewBox='0 0 17 16'
                        fill='none'
                      >
                        <path
                          d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                          fill='none'
                        />
                      </svg>
                    </button>
                  </div>
                </Swiper>
              </div>
            </div>

            {/* Find your solution mobile */}
            {props.mainButton?.link && (
              <IKLink
                link={props.mainButton?.link}
                className={`z-10 flex w-full rounded-xl border bg-white bg-opacity-80 px-4 py-2 font-medium hover:bg-opacity-95 lg:hidden ${
                  industryColor === 'white'
                    ? 'text-[#51504D] hover:text-[#51504D]'
                    : ''
                } ${industryColor === 'orange' ? 'text-[#91451C] hover:text-[#91451C]' : ''} ${
                  industryColor === 'teal'
                    ? 'text-[#025057] hover:text-[#025057]'
                    : ''
                } ${industryColor === 'pink' ? 'text-[#5F1222] hover:text-[#5F1222]' : ''} ${
                  industryColor === 'yellow'
                    ? 'text-[#775705] hover:text-[#775705]'
                    : ''
                } items-center justify-center gap-4 shadow-lg lg:hidden`}
              >
                <p>Find your solution</p>
                <BsArrowRightCircleFill size={20} />
              </IKLink>
            )}
          </div>

          {/* Related Article */}
          <div className='z-10 col-span-1 flex h-full flex-col justify-between gap-4 pb-4'>
            <h3
              className={`heading-md font-display font-semibold tracking-tighter ${industryColor === 'white' || industryColor === 'yellow' ? 'text-[#282825]' : 'text-white drop-shadow-lg'}`}
            >
              {props.subHeading}
            </h3>
            <m.div
              initial={{ opacity: 0, y: 40 }}
              animate={
                spotlightInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }
              }
              whileHover={{
                scale: 1.02,
                transition: { duration: 0.2, ease: 'easeInOut', delay: 0 }, // no delay here
              }}
              transition={{
                opacity: { duration: 0.6, delay: 0.4 },
                y: { duration: 0.6, delay: 0.4 },
              }}
              className='hidden lg:block'
            >
              {props.relatedArticle?.slug && (
                <IKLink
                  link={{
                    url: `blog/${props.relatedArticle?.slug}`,
                    type: 'internal',
                    document: 'page',
                  }}
                >
                  <div
                    className='group flex h-96 items-end justify-center rounded-2xl p-4'
                    style={{
                      backgroundImage: `url(${props.relatedArticle?.featuredImage.url})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className='w-full rounded-2xl bg-gray-600 bg-opacity-30 p-3 text-lg backdrop-blur-sm duration-200 group-hover:bg-opacity-40'>
                      <h3 className='line-clamp-2'>
                        {props.relatedArticle?.title}
                      </h3>
                    </div>
                  </div>
                </IKLink>
              )}
            </m.div>
            <m.div
              whileHover={{
                scale: 1.02,
                transition: { duration: 0.2, ease: 'easeInOut', delay: 0 }, // no delay here
              }}
              className='block lg:hidden'
            >
              {props.relatedArticle?.slug && (
                <IKLink
                  link={{
                    url: `blog/${props.relatedArticle?.slug}`,
                    type: 'internal',
                    document: 'page',
                  }}
                >
                  <div
                    className='group flex h-96 items-end justify-center rounded-2xl p-4'
                    style={{
                      backgroundImage: `url(${props.relatedArticle?.featuredImage.url})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className='w-full rounded-2xl bg-gray-600 bg-opacity-30 p-3 text-lg backdrop-blur-sm duration-200 group-hover:bg-opacity-40'>
                      <h3 className='line-clamp-2'>
                        {props.relatedArticle?.title}
                      </h3>
                    </div>
                  </div>
                </IKLink>
              )}
            </m.div>
          </div>
        </div>
      </m.div>
    </div>
  );
}
