export default function IKTopDividerWave({ color }: { color?: string }) {
  return (
    <div className='absolute left-0 top-0 h-max w-full -translate-y-[calc(100%_-_1px)] transform overflow-hidden'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className={`w-[200%] fill-${color || 'charcoal'} md:w-full`}
        width='1464'
        height='34'
        viewBox='0 0 1464 34'
        fill='none'
        preserveAspectRatio='none'
      >
        <path
          className={`fill-${color || 'charcoal'}`}
          d='M0 34H1464V13.9567C1462.14 14.0919 1459.76 14.2755 1456.86 14.4993L1456.79 14.5044C1400.39 18.8525 1148.67 38.2577 731.531 12.6924C315.789 -12.7874 65.0374 7.19974 7.26685 11.8046C4.35205 12.0369 1.92871 12.23 0 12.3763V34Z'
          fill='none'
        />
      </svg>
    </div>
  );
}
