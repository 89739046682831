'use client';
import { useRef, useState, useEffect } from 'react';
import { m } from 'framer-motion';
import { useAtom } from 'jotai';
import { currentIndustryAtom } from '@/store/industryStore';
import IndustryCard from '@/components/sections/industry-personalization/IndustryCard';
import { Industry } from '@/types/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper/modules';
import { IKButton } from '@/components/shared/material';
import { MenuItemLink } from '@/types/types';
import { ArrowRight } from '@phosphor-icons/react';

interface IndustryCarouselProps {
  industries: Industry[];
  setCurrentIndustry: (industry: Industry | null) => void;
  ctaHeading?: any;
  ctaCopy?: any;
  mainButton?: {
    text: string;
    link: MenuItemLink;
  };
  components: any;
}

export default function IndustryCarousel({
  industries,
  mainButton,
}: IndustryCarouselProps) {
  const [, setCurrentIndustry] = useAtom(currentIndustryAtom);
  const [highlightGlow, setHighlightGlow] = useState(false);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const sectionRef = useRef<HTMLDivElement>(null);

  const handleUserInteraction = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setHighlightGlow(false);
    timeoutRef.current = setTimeout(() => {
      setHighlightGlow(true);
    }, 3000); // 5 seconds timeout for glow effect
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        handleUserInteraction();
      } else {
        setHighlightGlow(false);
      }
    }, options);

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleInteraction = () => handleUserInteraction();

    if (sectionRef.current) {
      sectionRef.current.addEventListener('mousemove', handleInteraction);
      sectionRef.current.addEventListener('click', handleInteraction);
      sectionRef.current.addEventListener('keydown', handleInteraction);
    }

    return () => {
      if (sectionRef.current) {
        sectionRef.current.removeEventListener('mousemove', handleInteraction);
        sectionRef.current.removeEventListener('click', handleInteraction);
        sectionRef.current.removeEventListener('keydown', handleInteraction);
      }
    };
  }, []);

  return (
    <div>
      <Swiper
        className='my-4 !flex !h-full flex-col !overflow-visible'
        modules={[Navigation, Scrollbar, A11y]}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
          disabledClass: 'disabled',
        }}
        slidesPerView='auto'
        spaceBetween={15}
      >
        {industries?.map((industry, index) => (
          <SwiperSlide key={index} className='!w-auto'>
            <m.button
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                type: 'spring',
                stiffness: 150,
                damping: 12,
                delay: 0.4,
              }}
              onClick={() => setCurrentIndustry(industries[index])}
              className={`relative min-w-[300px] max-w-[300px] overflow-hidden rounded-[24px]`}
            >
              <IndustryCard
                title={industry.title}
                description={industry.description}
                industryColor={industry.industryColor}
                industryIcon={industry.industryIcon}
              />
            </m.button>
          </SwiperSlide>
        ))}
      </Swiper>
      <IKButton
        link={mainButton?.link}
        className={`group flex w-full cursor-pointer items-center justify-center gap-3 border border-gray-600 !bg-[#282825] !text-[#EFE9DB] !shadow-none hover:!bg-charcoal-800 sm:w-auto ${
          highlightGlow ? 'animate-pulse-glow' : ''
        }`}
      >
        <span>{mainButton?.text}</span>
        <span
          className={`flex h-6 w-6 items-center justify-center rounded-full bg-yellow text-charcoal duration-150 ease-out group-hover:ml-2 [&_path]:!stroke-charcoal [&_path]:!stroke-[8px]`}
        >
          <ArrowRight weight='bold' />
        </span>
      </IKButton>
    </div>
  );
}
